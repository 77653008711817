import React from 'react';
import CopyLinkIcon from '../../../assets/CopyLinkIcon.svg';
import ReportIcon from '../../../assets/ReportIcon.svg';

const VALID_TYPES = ['LINK_COPY', 'REPORT'];

const OptionItem = (props) => {

    const {
        type="LINK_COPY",
        onClick
    } = props;

    if (!VALID_TYPES.includes(type)) {
        console.error(`From { OptionItem.jsx } Invalid type: ${type}`);
        return <></>;
    }

    const getIconClasses = () => {
        if (type === 'LINK_COPY') {
            return `pnp-option-item-icon black`;
        } else if (type === 'REPORT') {
            return `pnp-option-item-icon red`;
        }
    };

    const getTextClasses = () => {
        if (type === 'LINK_COPY') {
            return `pnp-option-item-text black`;
        } else if (type === 'REPORT') {
            return `pnp-option-item-text red`;
        }
    };

    const getText = () => {
        if (type === 'LINK_COPY') {
            return `링크 복사`;
        } else if (type === 'REPORT') {
            return `신고`;
        }
    };

    const getIcon = () => {
        if (type === 'LINK_COPY') {
            return CopyLinkIcon;
        } else if (type === 'REPORT') {
            return ReportIcon;
        }
    };

    return (
        <button
            className={"pnp-option-item-container"}
            onClick={onClick}
        >
            <div
                className={getIconClasses()}
            >
                <img
                    src={getIcon()}
                    alt={type}
                />
            </div>
            <span
                className={getTextClasses()}
            >
                {getText()}
            </span>
        </button>
    );
};

export default OptionItem;