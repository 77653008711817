import api from "../../../api.js";

// Response type
// [
//     {
//         id: number,
//         title: string,
//         keywords: string[],
//         accepted_users: number[],
//         description: string,
//         created_at: string<"2025-03-09T12:10:35.312389+09:00">,
//         start_date: string<"2022-12-31">,
//         end_date: string<"2023-01-01">,
//     }
// ]

const fetchPublicProjectCardList = async (user_id) => {
    try {
        const response = await api.get(`api/project-card/public-list/${user_id}/`);
        return response.data;
    } catch (error) {
        console.error("Failed to fetch public project card info:", error);
        throw error;
    }
};

export default fetchPublicProjectCardList;
