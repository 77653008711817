import React, {useEffect, useState} from 'react';
import "../../styles/NewInvitation/NewInvitation.css";
import "../../styles/Experience/Experience.css";
import LoginTeamblIcon from "../../assets/loginteamblIcon.svg";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {toastText} from "../../components/Toast/Toast.jsx";
import InfoMessage from "../../components/InfoMessage.jsx";
import api from "../../api.js";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "../../constants.js";
import axios from "axios";
import loginAPI from "../../loginAPI.js";

/**
 *
 * 1. The user must NOT be authenticated.
 * 2. The location state must contain the invitation code. (`invitationCode` field)
 *    If not, navigate to the root page.
 * 3. The location state must contain the invitation information (`invitationInfo` field)
 *    If not, navigate to the root page.
 *
 * */
const InvitationLogin = () => {

    const navigate = useNavigate();
    const location = useLocation();

    if ((location.state == null)
        || (location.state.invitationInfo == null)
        || (location.state.invitationCode == null)) {
        toastText("잘못된 접근입니다.");
        return <Navigate to="/" />;
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isLoginFailed, setIsLoginFailed] = useState(false);
    const [isLoginLoading, setIsLoginLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const login = async () => {
        setIsLoginLoading(true);
        try {
            /** delete prev tokens */
            await localStorage.removeItem(ACCESS_TOKEN);
            const res = await loginAPI.post(`/api/token/`, { email, password });
            await localStorage.setItem(ACCESS_TOKEN, res.data.access);
            await localStorage.setItem(REFRESH_TOKEN, res.data.refresh);
            await localStorage.setItem("userId", res.data.userId);
            console.log("Stored userId:", localStorage.getItem("userId"));
            navigate(`/accept-invitation/accept`, {
                state : {
                    invitationCode: location.state.invitationCode
                }
            });
        } catch (error) {
            console.error("Error in login", error);
            toastText("이메일 또는 비밀번호가 일치하지 않습니다.");
            setIsLoginFailed(true);
        } finally {
            setIsLoginLoading(false);
        }
    };

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid = emailRegex.test(email);
        const isPasswordValid = password.trim() !== '';

        setIsValid(isEmailValid && isPasswordValid);
    }, [email, password]);

    return (
        <div
            className={"newInv-container"}
        >
            <img
                src={LoginTeamblIcon}
                alt={"Teambl Logo"}
            />
            <span
                className={"subtitle"}
            >
                {"신뢰 기반의 프로젝트 네트워크, 팀블!"}
            </span>
            <div
                className={"newInv-info-container"}
            >
                <div
                    className={"newInv-info-line"}
                >
                    <span
                        className={"text-primary"}
                    >
                        {location.state?.invitationInfo?.["inviter"]["profile"]["user_name"]}
                    </span>
                    <span
                        className={"text"}
                    >
                        {"님의 초대를 수락하시려면 로그인해주세요"}
                    </span>
                </div>
            </div>
            <div
                className={"new-inv-form-container"}
            >
                <div className='exp-inv-cer-email-input-row'>
                    <input
                        className='exp-inv-cer-email-input'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={"학교 이메일 입력"}
                        type={"email"}
                    />
                </div>
                <div
                    className='exp-inv-cer-email-input-row'
                    style={{
                        marginTop: '4px'
                    }}
                >
                    <input
                        className='exp-inv-cer-email-input'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={"비밀번호 입력"}
                        type={"password"}
                    />
                </div>
                {
                    isLoginFailed &&
                    <InfoMessage
                        type={"bad"}
                        message={"이메일 또는 비밀번호가 일치하지 않습니다."}
                    />
                }
            </div>
            <div
                className={"newInv-button-container"}
            >
                <button
                    className={`exp-save-button${isValid ? "" : " exp-btn-disabled"}`}
                    onClick={async () => {
                        if ((!isLoginLoading) && isValid) {
                            await login();
                        }
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {
                        isLoginLoading ?
                            <div
                                className={"exp-button-loader"}
                            >
                                {/* intentional empty */}
                            </div>
                            :
                            "로그인"
                    }
                </button>
            </div>
        </div>
    );
};

export default InvitationLogin;