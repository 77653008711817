import api from "../../../api.js";

/**
 * Accepts the invitation link and returns the project card
 *
 * @param {number} inviter_id - The id of the inviter
 * @param {number} project_card_id - The id of the project card
 * @returns {Promise} - The project card
 **/

const acceptInvitationLink = async (inviter_id, project_card_id) => {
    try {
        const response = await api.post(
            "api/project-card-invitation-link/accept-invitation/",
            {
                inviter_id: inviter_id,
                project_card_id: project_card_id,
            }
        );

        console.log(response);

        // 중복 가입 시도의 경우 (이미 가입했는데 또 가입하는 경우)
        const detail_message = response.data.detail;
        if (detail_message) {
            console.warn("이미 가입한 프로젝트 카드입니다.");
            return {
                duplicated: true,
                message: detail_message,
            };
        }

        return response.data;
    } catch (error) {
        console.error(
            "Error in Retreiving ProjectCardInvitationLink from code",
            error
        );
        throw error;
    }
};

export default acceptInvitationLink;
