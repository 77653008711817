import Modal from "./Modal.jsx";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

/**
 * ReportModal component
 * @param props {ReportModalProps}
 * @constructor
 */
export default function ReportModal(props) {
    const {isOpen, onClose, postId} = props;
    const navigate = useNavigate();
    const [text, setText] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsPopupOpen(true);
    }

    const goToLogin = () => {
        setIsPopupOpen(false);
        onClose();
        navigate("/");
    }

    if (isPopupOpen) return (
        <Modal isOpen={isPopupOpen} onClose={() => {
            setIsPopupOpen(false);
            onClose();
        }}>
            <div className="flex flex-col gap-3 items-center">
                <span className="text-sm font-bold text-center">신고를 위해서는 로그인이 필요합니다.</span>
                <button
                    onClick={goToLogin}
                    className="bg-[#0923A9] text-white p-2 border-none cursor-pointer hover:opacity-50 transition-all"
                >
                    확인
                </button>
            </div>
        </Modal>
    )

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <form onSubmit={handleSubmit} className="mt-5 flex flex-col gap-3">
                <span className="text-sm font-bold">신고하기</span>
                <textarea
                    onChange={(e) => setText(e.target.value)}
                    placeholder="신고 사유"
                    className="bg-[#F6F6F6] p-2 h-16 resize-none w-full rounded-sm border-none outline-none"
                />
                <button
                    disabled={text.length < 1}
                    className="bg-[#0923A9] text-white p-2 border-none cursor-pointer hover:opacity-50 transition-all disabled:bg-neutral-400 disabled:cursor-not-allowed"
                >
                    신고하기
                </button>
            </form>
        </Modal>
    )
}

/**
 * @typedef ReportModalProps
 * @property {boolean} isOpen
 * @property {function} onClose
 * @property {string} postId
 */