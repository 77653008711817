import React from 'react';
import ReusableBottomSheet from "../ReusableBottomSheet.jsx";
import "../../styles/ProjectAndPostPreview.css";
import {toastText} from "../Toast/Toast.jsx";
import OptionItem from "./optionItem/OptionItem.jsx";

const ProjectOptionModal = ({ isOpen, setIsOpen, projectId, userId }) => {

    const handleCopyLink = () => {
        navigator
            .clipboard
            .writeText(window.location.href)
            .then(r => {
                toastText("프로젝트 링크가 복사되었습니다.");
                setIsOpen(false);
            });
    };

    const handleOpenReport = () => {
        toastText("신고가 접수되었습니다.");
        setIsOpen(false);
    };

    return (
        <ReusableBottomSheet
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div
                className={"pnp-option-container"}
            >
                <OptionItem
                    type={"LINK_COPY"}
                    onClick={handleCopyLink}
                />
                <OptionItem
                    type={"REPORT"}
                    onClick={handleOpenReport}
                />
            </div>
        </ReusableBottomSheet>
    );
};

export default ProjectOptionModal;