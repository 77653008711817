import closeIcon from '../assets/closeIcon.svg';
import QuickPinchZoom, {make3dTransformValue} from "react-quick-pinch-zoom";
import {useCallback, useRef} from "react";

/**
 * @param props {ImagePreviewProps}
 * @constructor
 */
export default function ImagePreview(props) {
    const {isOpen, close, image} = props;
    const imgRef = useRef();

    const onUpdate = useCallback(({scale, x, y}) => {
        const {current: img} = imgRef;
        if (img) {
            const value = make3dTransformValue({scale, x, y});
            img.style.setProperty("transform", value);
        }
    }, []);

    const stopPropagation = (event) => {
        event.stopPropagation();
    }

    return (
        <div className={`fixed inset-0 z-50 ${isOpen ? "flex" : "hidden"}`}>
            <div
                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                onClick={close}
            >
                <QuickPinchZoom
                    onUpdate={onUpdate}
                    containerProps={{
                        className: "w-full h-full flex"
                    }}
                >
                    <img
                        src={image}
                        alt="preview"
                        className="bg-green-500 object-contain"
                        ref={imgRef}
                        onClick={stopPropagation}
                    />
                </QuickPinchZoom>
            </div>
            <div
                className="fixed top-5 right-5 p-3"
                onClick={close}
            >
                <img src={closeIcon} alt="close" className="w-5 h-5"/>
            </div>
        </div>
    );
}

/**
 * @typedef ImagePreviewProps
 * @property {boolean} isOpen
 * @property {function} close
 * @property {string} image
 */