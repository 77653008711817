import React from 'react';
import "../../styles/AnonymousProfile.css";

const TabMenu = (props) => {

    const { currentTab, setCurrentTab, tabList } = props;

    return (
        <div
            className='tabmenu-container'
        >
            {
                tabList.map((tab, index) => {
                    return (
                        <div
                            key={index}
                            className={`tabmenu-item ${currentTab === tab.key ? 'selected' : ''}`}
                            onClick={() => setCurrentTab(tab.key)}
                        >
                            {tab.name}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default TabMenu;