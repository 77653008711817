import {useMemo} from "react";

/**
 * PostContent component
 * @param props {PostContentProps}
 * @returns {JSX.Element}
 * @constructor
 */
export default function PostContent(props) {
    const mentionEntities = useMemo(() => {
        const entities = [];
        for (const taggedUser of props.taggedUsers) {
            const userId = taggedUser.id;
            const text = taggedUser.user_name;
            const matches = props.content.matchAll(new RegExp(text, "g"));
            for (const match of matches) {
                entities.push({userId, text, offset: match.index, length: text.length});
            }
        }
        entities.sort((a, b) => a.offset - b.offset);
        return entities;
    }, [props.content, props.taggedUsers]);

    const contentParts = useMemo(() => {
        const parts = [];
        let lastIndex = 0;
        for (const entity of mentionEntities) {
            if (entity.offset > lastIndex) {
                parts.push(props.content.slice(lastIndex, entity.offset));
            }
            parts.push(
                <a
                    key={lastIndex}
                    className="text-blue-500 no-underline font-semibold cursor-pointer"
                    href={`/profiles/${entity.userId}?view=info`}
                >
                    {entity.text}
                </a>
            );
            lastIndex = entity.offset + entity.length;
        }
        if (lastIndex < props.content.length) {
            parts.push(props.content.slice(lastIndex));
        }
        return parts;
    }, [props.content, props.taggedUsers, props.projId]);

    return (
        <p style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            {contentParts}
        </p>
    )
}

/**
 * @typedef PostContentProps
 * @property {string} content
 * @property {Array<TaggedUser>} taggedUsers
 * @property {number} projId
 */

/**
 * @typedef TaggedUser
 * @property {number} id
 * @property {string} user_name
 */