import api from "../../../api.js";

const searchSkills = async (query) => {
    try {
        const params = {
            query: query
        };
        const response = await api.get("api/skill/search", {
            params
        });
        return response.data;
    } catch (error) {
        console.error("Failed to search skills", error);
        return null; // 실패 시 null 반환
    }
};

export default searchSkills;
