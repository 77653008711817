const dummyProjects = [
    {
        id: 1,
        title: "Teambl: 신뢰기반 팀원 찾기 플랫폼",
        keywords: ["AI", "HR", "Resume"],
        accepted_users: [1, 2],
        bookmarked_users: [3, 4],
        pending_invited_users: [5],
        creator: {
            id: 1,
            email: "alice@example.com",
            last_login: new Date(),
            is_superuser: false,
            is_staff: true,
            is_active: true,
            date_joined: new Date("2023-02-15"),
            profile: { name: "Alice" }
        },
        created_at: new Date("2024-02-01"),
        start_date: new Date("2024-03-01"),
        end_date: null,
        description: "진행중인 프로젝트를 공유함, 관심 있는 프로젝트 현황을 살펴봄, 지인 네트워크를 활용한 팀원 매칭 및 지식 Q&A. 신뢰 기반 프로젝트 네트워크 서비스, 팀블! 진행중인 프로젝트를 공유함, 관심 있는 프로젝트 현황을 살펴봄, 지인 네트워크를 활용한 팀원 매칭 및 지식 Q&A. 신뢰 기반 프로젝트 네트워크 서비스, 팀블.",
        posts: [
            {
                id: 1,
                project_card: null, // Placeholder, should be replaced with actual project reference
                user: {
                    id: 2,
                    email: "bob@example.com",
                    last_login: new Date(),
                    is_superuser: false,
                    is_staff: false,
                    is_active: true,
                    date_joined: new Date("2023-03-10"),
                    profile: { name: "Bob" }
                },
                content: "첫번째 난관: 스마트폰과 블루투스로 통신하여 전자기기를 제어할 수 있는 리모컨 제작을 목표로 프로젝트를 시작하였으나 처음부터 난관이 생겨버렸습니다. ",
                created_at: new Date("2024-04-15"),
                like_count: 5,
                tagged_users: [],
                liked_users: [],
                images: [],
                comments: []
            }
        ],
        invite_id: 101
    },
    {
        id: 2,
        title: "Blockchain Voting System",
        keywords: ["Blockchain", "Security", "Voting"],
        accepted_users: [2, 3],
        bookmarked_users: [1, 5],
        pending_invited_users: [],
        creator: {
            id: 3,
            email: "charlie@example.com",
            last_login: new Date(),
            is_superuser: false,
            is_staff: false,
            is_active: true,
            date_joined: new Date("2022-07-22"),
            profile: { name: "Charlie" }
        },
        created_at: new Date("2024-01-10"),
        start_date: new Date("2024-02-01"),
        end_date: new Date("2024-12-31"),
        description: "Developing a secure and transparent blockchain-based voting system.",
        posts: [],
        invite_id: 102
    },
    {
        id: 3,
        title: "IoT-Based Smart Farming",
        keywords: ["IoT", "Agriculture", "Automation"],
        accepted_users: [1, 4],
        bookmarked_users: [2, 3],
        pending_invited_users: [5],
        creator: {
            id: 4,
            email: "dave@example.com",
            last_login: new Date(),
            is_superuser: false,
            is_staff: true,
            is_active: true,
            date_joined: new Date("2021-12-11"),
            profile: { name: "Dave" }
        },
        created_at: new Date("2024-05-05"),
        start_date: new Date("2024-06-01"),
        end_date: new Date("2025-05-31"),
        description: "Creating an IoT-enabled automated irrigation system.",
        posts: [],
        invite_id: 103
    }
];

dummyProjects[0].posts[0].project_card = JSON.parse(JSON.stringify(dummyProjects[0]));

const getDummy = async (index) => {
    if ((index > 2) || (index < 0)) {
        return 0;
    }
    return JSON.parse(JSON.stringify(dummyProjects[index]));
};

export const ProjectDummyData = {
    getDummy
}