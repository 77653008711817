import React, {useEffect, useState} from 'react';
import "../styles/SkillInput.css";
import ReusableBottomSheet from "./ReusableBottomSheet.jsx";
import majorEdit from "../assets/Profile/majorEdit.svg";
import fetchRecommendedSkills from "../libs/apis/Skill/searchRecommendedSkill.js";
import searchSkills from "../libs/apis/Skill/searchSkill.js";
import {toastText} from "./Toast/Toast.jsx";
import PrimeButton from "./PrimeButton.jsx";

const SkillInput = ({ isOpen, setIsOpen, selectedSkillList, updateSelectedSkillList }) => {

    const [recommendedSkillList, setRecommendedSkillList] = useState([]);
    const [searchSkillList, setSearchSkillList] = useState([]);
    const [searchText, setSearchText] = useState("");

    const addSkill = (skill) => {
        if ((skill == null) || (skill.trim() === "")) {
            return;
        }
        if (selectedSkillList.includes(skill)) {
            toastText("이미 추가된 스킬입니다.");
            return false;
        }
        updateSelectedSkillList(prevState => {
            let newState = [...prevState];
            newState.push(skill);
            return newState;
        });
        return true;
    };

    const removeSkill = (skill) => {
        updateSelectedSkillList(prevState => {
            let newState = [...prevState];
            newState = newState.filter((tempSkill) => tempSkill !== skill);
            return newState;
        });
    };

    const fetchRecommendedSkill = async () => {
        const recommendedSkill = await fetchRecommendedSkills();
        if (recommendedSkill) {
            setRecommendedSkillList(recommendedSkill);
        } else {
            setRecommendedSkillList([]);
            console.log("Failed to fetch recommended skills");
        }
    };

    const searchSkillWithQuery = async (query) => {
        const newSkills = await searchSkills(query);
        if (newSkills) {
            setSearchSkillList(newSkills);
        } else {
            setSearchSkillList([]);
            console.log("Failed to search skills");
        }
    };

    useEffect(() => {
        fetchRecommendedSkill();
    }, []);

    useEffect(() => {
        searchSkillWithQuery(searchText);
    }, [searchText]);

    useEffect(() => {
        setSearchText("");
    }, [selectedSkillList]);

    return (
        <ReusableBottomSheet
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div
                className={"skill-input-container"}
            >
                {/* title */}
                <span
                    className={"skill-input-title"}
                >
                    {"스킬"}
                </span>
                {/* skill input */}
                <div
                    className="newSearch-major-search-container"
                    style={{
                        marginTop: '12px',
                        width: '100%',
                    }}
                >
                    <img
                        src={majorEdit}
                        alt="스킬 아이콘"
                        className="newSearch-major-search-icon"
                    />
                    <input
                        type="text"
                        placeholder={"스킬 입력"}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="newSearch-major-search-input"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (e.nativeEvent.isComposing) return;
                                addSkill(searchText);
                            }
                        }}
                    />
                </div>
                {/* skill list view */}
                <div
                    className={"skill-list-view-container"}
                >
                    {
                        (selectedSkillList.length <= 0) &&
                        recommendedSkillList.map((skill, index) => {
                            return (
                                <div
                                    key={index + skill}
                                    onClick={() => addSkill(skill)}
                                    className={"skill-item"}
                                >
                                    {skill}
                                </div>
                            );
                        })
                    }
                    {
                        (selectedSkillList.length > 0) &&
                        selectedSkillList.map((skill, index) => {
                            return (
                                <div
                                    key={index + skill}
                                    onClick={() => removeSkill(skill)}
                                    className={"skill-item selected"}
                                >
                                    {skill}
                                </div>
                            );
                        })
                    }
                    {
                        searchSkillList.map((skill, index) => {
                            return (
                                <div
                                    key={index + skill}
                                    onClick={() => addSkill(skill)}
                                    className={"skill-item"}
                                >
                                    {skill}
                                </div>
                            );
                        })
                    }
                </div>
                {/* save button */}
                <div style={{ width: "100%", marginTop: '16px' }}>
                    <PrimeButton
                        text={"스킬 추가"}
                        onClickCallback={() => {
                            addSkill(searchText);
                        }}
                        isActive={(searchText != null) && (searchText.trim() !== "")}
                        isLoading={false}
                    />
                </div>
            </div>
        </ReusableBottomSheet>
    );
};

export default SkillInput;