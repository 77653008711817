import React, {useEffect, useState} from 'react';
import "../../styles/ProjectAndPostPreview.css";
import SuspenseBox from "../SuspenseBox.jsx";
import {ProjectDummyData} from "./ProjectDummyData.jsx";
import ProjectOptionIcon from "../../assets/ProjectOptionIcon.svg";
import {dummyUserList} from "./ProjectUserDummyData.jsx";
import DefaultProfile from "../../assets/defaultProfile.svg";
import PostPreview from "./PostPreview.jsx";
import ReusableBottomSheet from "../ReusableBottomSheet.jsx";
import ProjectOptionModal from "./ProjectOptionModal.jsx";
import fetchPublicProjectCardList from "../../libs/apis/ProjectCard/fetchPublicProjectCardList.js";
import getPublicUserInfo from "../../libs/apis/User/getPublicUserInfo.js";

const KeywordBadge = (props) => {
    const {
        label
    } = props;

    return (
        <div
            className={"pnp-badge-item"}
        >
            <span
                className={"pnp-badge-item-text"}
            >
                {label}
            </span>
        </div>
    );
}

const ProjectPreview = (props) => {

    const {
        projectId,
        userId
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [projectData, setProjectData] = useState({});
    const [projectUserData, setProjectUserData] = useState({});
    const [isError, setIsError] = useState(false);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

    const fetchProjectData = async () => {
        setIsLoading(true);
        // const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        // TODO : Fetch Real Data
        try {
            const res = await fetchPublicProjectCardList(userId);
            let isFound = false;
            for (let i = 0; i < res.length; i++) {
                if (`${res[i].id}` === `${projectId}`) {
                    setProjectData(res[i]);
                    const userList = await fetchProjectUserData(res[i].accepted_users);
                    setProjectUserData(userList);
                    isFound = true;
                    break;
                }
            }
            if (!isFound) {
                throw new Error("Failed to fetch project data");
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsError(true);
            setIsLoading(false);
        }
    };

    const fetchProjectUserData = async (userIdList) => {
        const results = await Promise.all(userIdList.map(async (userId) => {
            try {
                return await getPublicUserInfo(userId);
            } catch (error) {
                console.error(`Error fetching user info for ${userId}:`, error);
                return null;
            }
        }));

        return results.filter(Boolean);
    };


    const formatDate = (input) => {
        const date = typeof input === "string" ? new Date(input) : input;
        if (isNaN(date?.getTime())) return "0000.00";

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");

        return `${year}.${month}`;
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    if (isLoading) {
        /** suspense view */
        return (
            <div
                className={"pnp-container"}
            >
                {/* title */}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <SuspenseBox
                        styleOv={{
                            width: "200px",
                            height: "20px"
                        }}
                    />
                    <SuspenseBox
                        styleOv={{
                            width: "15px",
                            height: "20px"
                        }}
                    />
                </div>
                {/* keywords */}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        marginTop: "12px",
                        gap: "8px"
                    }}
                >
                    <SuspenseBox
                        styleOv={{
                            width: "50px",
                            height: "17px"
                        }}
                    />
                    <SuspenseBox
                        styleOv={{
                            width: "50px",
                            height: "17px"
                        }}
                    />
                    <SuspenseBox
                        styleOv={{
                            width: "50px",
                            height: "17px"
                        }}
                    />
                </div>
                {/* description */}
                <div
                    style={{
                        width: "100%",
                        marginTop: "20px"
                    }}
                >
                    <SuspenseBox
                        styleOv={{
                            width: "100%",
                            height: "60px"
                        }}
                    />
                </div>
                {/* date and images */}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "20px",
                        gap: "33px"
                    }}
                >
                    <SuspenseBox
                        styleOv={{
                            width: "56px",
                            height: "14px"
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <SuspenseBox
                            styleOv={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "10px",
                                border: "1px solid #fff"
                            }}
                        />
                        <SuspenseBox
                            styleOv={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "10px",
                                border: "1px solid #fff",
                                marginLeft: "-6px"
                            }}
                        />
                        <SuspenseBox
                            styleOv={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "10px",
                                border: "1px solid #fff",
                                marginLeft: "-6px"
                            }}
                        />
                    </div>
                </div>
                {/** posts */}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "20px",
                        gap: "12px"
                    }}
                >
                    <SuspenseBox
                        styleOv={{
                            width: "100%",
                            height: "140px"
                        }}
                    />
                </div>
            </div>
        );
    }

    if ((!isLoading) && isError) {
        return (
            <div
                className={"pnp-container"}
            >
                <span
                    className={"pnp-error-title"}
                >
                    {"죄송합니다."}
                </span>
                <span
                    className={"pnp-error-content"}
                >
                    {"데이터를 불러오는 중에 오류가 발생했습니다."}
                </span>
            </div>
        );
    }

    return (
        <div
            className={"pnp-container"}
        >
            {/* title */}
            <div
                className={"pnp-title-container"}
            >
                <span
                    className={"pnp-title"}
                >
                    {projectData.title}
                </span>
                {/* option button */}
                <button
                    className={"pnp-option-button"}
                    onClick={() => setIsBottomSheetOpen(true)}
                >
                    <img
                        src={ProjectOptionIcon}
                        alt={"options"}
                    />
                </button>
            </div>
            {/* keywords */}
            {
                (projectData.keywords.length > 0) &&
                <div
                    className={"pnp-badge-container"}
                >
                    {
                        projectData.keywords.map((keyword, idx) => {
                            return (
                                <KeywordBadge
                                    key={idx}
                                    label={keyword}
                                />
                            );
                        })
                    }
                </div>
            }
            {/* description */}
            <div
                className={"pnp-description-container-no-cut"}
            >
                {projectData.description}
            </div>
            {/* footer */}
            <div
                className={"pnp-footer-container"}
            >
                {/* date */}
                {
                    (formatDate(projectData.start_date) != "0000.00") && 
                    <span
                        className={"pnp-footer-date"}
                    >
                        {`${formatDate(projectData.start_date)} ~`}
                        {
                            (projectData.end_date) &&
                            `${formatDate(projectData.end_date)}`
                        }
                    </span>
                }
                {/* horizontal line */}
                {
                    (formatDate(projectData.start_date) != "0000.00") &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="20" viewBox="0 0 2 20" fill="none">
                        <path d="M1 0L1 20" stroke="#D9D9D9" strokeLinejoin="round"/>
                    </svg>
                }
                {/* member profile images */}
                {
                    (projectUserData.length > 0) &&
                    <div
                        className={"pnp-footer-image-container"}
                    >
                        {
                            projectUserData.map((user, idx) => {
                                if ((idx === 0) || (idx === 1)) {
                                    return (
                                        <img
                                            key={idx}
                                            className={`pnp-footer-image${(idx === 1) ? ' others' : ''}`}
                                            src={user.profile.image ? user.profile.image : DefaultProfile}
                                            alt={"profile"}
                                        />
                                    );
                                } else if (idx === 2) { /** numbers */
                                    return (
                                        <div
                                            key={idx}
                                            className={"pnp-footer-numbers"}
                                        >
                                            {`+${projectUserData.length - 2}`}
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        }
                    </div>
                }
            </div>
            {/* bottom sheet */}
            <ProjectOptionModal
                isOpen={isBottomSheetOpen}
                setIsOpen={setIsBottomSheetOpen}
                projectId={projectId}
                userId={userId}
            />
            {/* posts : dummy view */}
            <div
                className={"pnp-post-dummy-container"}
            >
                {"게시물을 보려면 앱으로 로그인"}
            </div>
            {/* posts */}
            {/*{*/}
            {/*    (projectData.posts.length > 0) &&*/}
            {/*    <div*/}
            {/*        className={"pnp-posts-container"}*/}
            {/*    >*/}
            {/*        {*/}
            {/*            projectData.posts.length > 0 && projectData.posts[0] ? (*/}
            {/*                <PostPreview*/}
            {/*                    key={projectData.posts[0].id}*/}
            {/*                    postData={projectData.posts[0]}*/}
            {/*                />*/}
            {/*            ) : null*/}
            {/*        }*/}
            {/*        /!* {*/}
            {/*            projectData.posts.map((postData, idx) => {*/}
            {/*                return (*/}
            {/*                    <PostPreview*/}
            {/*                        key={`${idx}` + `${postData.id}`}*/}
            {/*                        postData={postData}*/}
            {/*                    />*/}
            {/*                );*/}
            {/*            })*/}
            {/*        } *!/*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
};

export default ProjectPreview;