import { useEffect, useState } from "react";

export default function PostDetailViewLoginCard() {
    const [appLink, setAppLink] = useState("");
    const appStoreURL = "https://apps.apple.com/app/teambl/id6742453262"; // iOS 앱스토어 링크
    const playStoreURL = "https://play.google.com/store/apps/details?id=com.teambl.teambl"; // 안드로이드 플레이스토어 링크
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    useEffect(() => {
      // 현재 웹 URL을 가져와서 앱 딥링크로 변환하는 함수
      const getAppLink = () => {
        const webUrl = window.location.href; // 현재 웹 URL
        const baseDomain = "https://teambl.net"; // 웹 도메인
        const appScheme = "teambl://"; // 앱 스킴
    
        if (webUrl.startsWith(baseDomain)) {
          const path = webUrl.replace(baseDomain, ""); // 도메인 제거
          return appScheme + path; // 앱 링크로 변환
        }
        return appScheme; // 기본 앱 링크 (예외 처리)
      };
    
      setAppLink(getAppLink());
    }, []);


    const openStore = () => {
        // 앱 실행 시도
        window.location.href = appLink;
        let isAppOpened = false;
      
        const handleVisibilityChange = () => {
            if (document.visibilityState === "hidden") {
                isAppOpened = true; // 앱이 실행됨
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        var time = 1000;
        if (isIOS && navigator.userAgent.toLowerCase().indexOf("safari") > -1){
            time = 2500;
        }
        setTimeout(() => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        
            if (!isAppOpened) {
                window.location.href = isIOS ? appStoreURL : playStoreURL;
            }
        }, time);
    };

    return (
        <div 
            style={{
                width: '100%',
                padding: '24px',
                backgroundColor: 'white',
                textAlign: 'center',
                borderTop: '5px solid #E5E7EB',
            }}
        >
            <h2 
                style={{
                    fontSize: '18px',
                    fontWeight: '600',
                    color: '#111827',
                    marginBottom: '8px',
                    fontFamily: 'Pretendard'
                }}
            >
                앱을 통해 게시물 댓글 확인하기
            </h2>
            <p 
                style={{
                    fontSize: '14px',
                    color: '#6B7280',
                    marginBottom: '16px',
                    fontFamily: 'Pretendard'
                }}
            >
                가입하면 이 게시글에 대해<br/>
                더 많은 이야기를 나눌 수 있어요!
            </p>
            <button 
                style={{
                    width: '100%',
                    backgroundColor: '#0000b3',
                    color: 'white',
                    fontWeight: '600',
                    fontSize: '14px',
                    height: '44px',
                    border: 'none',
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Pretendard'
                }}
                onClick={openStore}
            >
                팀블 앱으로 보기
            </button>
        </div>
    );
}