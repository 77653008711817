import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import "../styles/AnonymousProfile.css";
import DefaultProfile from "../assets/defaultProfile.svg";
import ChonIcon from "../assets/chonCount.svg";
import TabMenu from '../components/AnonymousProfile/TabMenu';
import InfoView from '../components/AnonymousProfile/InfoView';
import ProjectPreview from "../components/ProjectAndPostPreview/ProjectPreview.jsx";
import ProjectLoginCard from "../components/ProjectAndPostPreview/ProjectLoginCard.jsx";
import InfoLoginCard from '../components/AnonymousProfile/InfoLoginCard.jsx';
import getPublicUserInfo from "../libs/apis/User/getPublicUserInfo.js";

/**
 * Example : https://teambl.net/profiles/${userId}?view=project&projId=${projId}
 * Query Params :
 * - 1. view
 * - 2. projId
 */

const profileDummy = {
    user_name: "김팀블",
    school: "카이스트",
    current_academic_degree: "학사",
    year: 2018,
    major1: "전산학부",
    major2: "전기및전자공학부",
    image: "https://blog.kakaocdn.net/dn/qJZuh/btrNyFgCkQ7/MzbuYV82kcmXqCUoFZqqP0/img.jpg",
    one_degree_count: 100,
    introduction: "안녕하세요! 저는 프론트엔드와 백엔드 개발을 모두 경험하며, AI와 로봇공학에도 깊은 관심을 가지고 있는 개발자입니다. \nReact와 Vue를 활용한 웹 프론트엔드 개발부터, Node.js와 Python을 이용한 서버 구축까지 폭넓은 기술 스택을 보유하고 있습니다.\n또한, C++을 사용하여 로봇 소프트웨어를 개발한 경험도 있으며, AI 및 머신러닝(ML) 모델을 연구하고 응용하는 데에도 열정을 가지고 있습니다.",
    skills: ["리액트", "Vue", "Node.js", "Python", "C++", "Robotics", "AI", "ML"],
    keywords: ["개발", "프론트엔드", "백엔드", "AI", "ML", "로봇공학"]
};

const profileDummy2 = {
    user_name: "유팀블",
    school: "카이스트",
    current_academic_degree: "학사",
    year: 2018,
    major1: "전산학부",
    major2: null,
    image: null,
    one_degree_count: 2,
    introduction: null,
    skills: [],
    keywords: []
};

const VALID_TYPES = ['project', 'info'];

const AnonymousProfile = () => {

    const navigate = useNavigate();
    const { userId } = useParams();
    const [searchParams] = useSearchParams();
    const view = searchParams.get('view');
    const projectId = searchParams.get('projId');

    if ((!VALID_TYPES.includes(view)) || (!userId)) {
        alert('비정상적인 접근입니다.');
        navigate('/');
        return (
            <></>
        );
    }

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [profileData, setProfileData] = useState({});
    const [error, setError] = useState(false);
    const [currentTab, setCurrentTab] = useState('project'); // info | project

    const fetchProfileData = async () => {
        setIsDataLoading(true);
        try {
            // TODO : Fetch Real Data
            const res = await getPublicUserInfo(userId);
            // console.log(res);
            setProfileData(res.profile);
            setIsDataLoading(false);
            // setTimeout(() => {
            //     setProfileData(profileDummy);
            //     setIsDataLoading(false);
            // }, 2000);
        } catch (error) {
            console.log(error);
            setError(true);
            setIsDataLoading(false);
        }
    };

    const getYear = (year) => {
        let yearStr = `${year}`;
        return yearStr[2] + yearStr[3] + " 학번";
    }

    useEffect(() => {
        fetchProfileData();
    }, []);

    if (isDataLoading) {
        return (
            <div className="anprof-loader-container">
                <div className="anprof-loader" />
            </div>
        );
    }

    if ((!isDataLoading && error) || !profileData) {
        return (
            <div
                className="anprof-error-container "
            >
                <span
                    className='anprof-error-title'
                >
                    {"죄송합니다."}
                </span>
                <span
                    className='anprof-error-content'
                >
                    {"데이터를 불러오는 중에 오류가 발생했습니다."}
                </span>
            </div>
        );
    }

    return (
        <div
            className='anprof-container'
        >
            <div
                className='anprof-header-container'
            >
                <img
                    className='anprof-profile-image'
                    src={profileData.image ? profileData.image : DefaultProfile}
                    alt='profile'
                />
                {/** name */}
                <span
                    className='anprof-name'
                >
                    {profileData.user_name}
                </span>
                {/** school info */}
                <div
                    className='anprof-school-info-container'
                >
                    <span
                        className='anprof-content-text'
                    >
                        {/* {profileData.school} */}
                        ?
                    </span>
                    <span
                        className='anprof-content-text-sm'
                    >
                        {'|'}
                    </span>
                    <span
                        className='anprof-content-text'
                    >
                        {/* {profileData.current_academic_degree} */}
                        ?
                    </span>
                    <span
                        className='anprof-content-text-sm'
                    >
                        {'|'}
                    </span>
                    <span
                        className='anprof-content-text'
                    >
                        {/* {getYear(profileData.year)} */}
                        ? 학번
                    </span>
                </div>
                {/** dept info */}
                <div
                    className='anprof-school-info-container'
                    style={{ marginTop: '8px' }}
                >
                    <span
                        className='anprof-content-text'
                    >
                        {/* {`${profileData.major1}${profileData.major2 ? ` ・ ${profileData.major2}` : ''}`} */}
                        ? 학과
                    </span>
                </div>
                {/** chon count */}
                <div
                    className='anprof-chon-count-container'
                >
                    <img
                        src={ChonIcon}
                        alt='chon'
                    />
                    <span
                        className='anprof-chon-count-text'
                    >
                        {`1촌 ? 명`}
                    </span>
                </div>
            </div>
            <TabMenu
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                tabList={[{
                    name: '프로젝트',
                    key: 'project'
                },
                {
                    name: '정보',
                    key: 'info'
                }]}
            />
            {
                (currentTab === 'project') &&
                <div
                    className={"anprof-project-preview-container"}
                >
                    <ProjectPreview
                        projectId={projectId}
                        userId={userId}
                    />
                    <ProjectLoginCard />
                </div>
            }
            {
                (currentTab === 'info') &&
                <InfoLoginCard/>
                // <InfoView
                //     userId={userId}
                //     profileData={profileData}
                // />
            }
        </div>
    );
};

export default AnonymousProfile;