import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const NewEnd = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const newName = location?.state?.user_name || "";

    const [isValidFlow, setIsValidFlow] = useState(false);
    const [isStartLoading, setIsStartLoading] = useState(false);

    const handleStart = async () => {
        if (isStartLoading) {
            return;
        }
        navigate("/");
    };

    /** effects */
    useEffect(() => {
        if (!isValidFlow) {
            let isProcessValid = (newName !== "");
            if ((!isProcessValid)) {
                alert("비정상적인 접근입니다.");
                navigate('/');
            }
            setIsValidFlow(isProcessValid);
        }
    }, []);

    return (
        <div
            className="exp-body exp-with-pd-28"
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingBottom: "50px"
            }}
        >
            {/** title */}
            <div
                className='exp-inv-title-container'
            >
                <div className='exp-inv-title-row'>
                    <span className='exp-inv-title primary-color'>
                        {`${newName}`}
                    </span>
                    <span className='exp-inv-title'>
                        {`님,`}
                    </span>
                </div>
                <div className='exp-inv-title-row'>
                    <span className='exp-inv-title'>
                        {`가입을 축하합니다.`}
                    </span>
                </div>
            </div>
            {/** sub-title */}
            <div className='exp-inv-subtitle-container'>
                <span className='exp-inv-subtitle'>
                    {"이제 팀블과 함께 최적의 팀원을 탐색해 보세요!"}
                </span>
            </div>
            {/** caption */}
            <div className='exp-inv-caption-container'>
                <span className='exp-inv-caption'>
                    {"프로필을 더 자세히 작성할수록 다른 회원들과 더 쉽게 연결될 수 있습니다. 이어서 프로필을 작성해 볼까요?"}
                </span>
            </div>
            {/** buttons */}
            <div
                className='exp-inv-bottom-button-container'
                style={{marginTop: "auto"}}
            >
                {/* <button
                    className='exp-save-button no-mg'
                    onClick={async () => await handleStart()}
                >
                    {"프로필 추가로 작성하기"}
                </button> */}
                <button
                    className='exp-save-button no-mg'
                    onClick={async () => await handleStart()}
                >
                    {"팀블 시작하기"}
                </button>
            </div>
        </div>
    );
};

export default NewEnd;