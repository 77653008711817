import {useNavigate, useParams} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import likeIcon from '../assets/NewProject/project_likes_icon.svg';
import commentsIcon from '../assets/NewProject/CommentCircle.svg'
import threeDotsIcon from '../assets/three_dots.svg';
import reportIcon from '../assets/NewProject/project_report_icon.svg';
import linkIcon from '../assets/NewProject/project_link_icon.svg';
import PostImages from "../components/PostImages.jsx";
import Modal from "../components/Modal.jsx";
import {toastText} from "../components/Toast/Toast.jsx";
import ReportModal from "../components/ReportModal.jsx";
import ProjectInvitedUsers from "../components/ProjectInvitedUsers.jsx";
import PostContent from "../components/PostContent.jsx";
import api from "../api.js";
import PostDetailViewLoginCard from "../components/PostDetailViewLoginCard.jsx";

// The data received from backend route is shaped like below:
const mockPost1 = {
    "id": 1,
    "project_card": {
        "id": 1,
        "title": "유저1의 프로젝트1",
        "accepted_users": [
            {
                "id": 1,
                "user_name": "유저1",
                "image": "https://teambl-bucket.s3.amazonaws.com/post_images/1/e15f3e6f-92b2-4afb-bf8d-6ee3f6557fce.JPG"
            },
            {
                "id": 2,
                "user_name": "유저2",
                "image": "https://teambl-bucket.s3.amazonaws.com/post_images/1/0915b804-2a00-42fe-9926-a2fc5a91ce60.jpg"
            },
            {
                "id": 3,
                "user_name": "유저3",
                "image": "https://teambl-bucket.s3.amazonaws.com/post_images/1/e15f3e6f-92b2-4afb-bf8d-6ee3f6557fce.JPG"
            }
        ]
    },
    "content": "첫번째 게시글입니다. (수정). The user 유저2 and 유저3 are tagged. But the user 유저2 is tagged twice. And 유저1 is not tagged.",
    "created_at": "2025-02-22T11:56:49.537965+09:00",
    "like_count": 0,
    "comment_count": 0,
    "tagged_users": [
        {
            "id": 2,
            "user_name": "유저2"
        },
        {
            "id": 3,
            "user_name": "유저3"
        }
    ],
    "images": [
        {
            "id": 18,
            "image": "https://teambl-bucket.s3.amazonaws.com/post_images/1/0915b804-2a00-42fe-9926-a2fc5a91ce60.jpg",
            "created_at": "2025-02-23T19:45:25.478688+09:00"
        },
        {
            "id": 19,
            "image": "https://teambl-bucket.s3.amazonaws.com/post_images/1/e15f3e6f-92b2-4afb-bf8d-6ee3f6557fce.JPG",
            "created_at": "2025-02-23T19:54:41.845294+09:00"
        }
    ]
};

export default function PostDetailView() {
    const {postId} = useParams();
    const navigate = useNavigate();
    const [postData, setPostData] = useState();
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);

    useEffect(() => {
        if (!postId) {
            toastText("존재하지 않는 리소스에 액세스하려고 합니다.")
            navigate("/");
            return;
        }
        // Fetch post details from backend
        fetchPost(postId);
    }, [postId]);

    const fetchPost = async (postId) => {
        try {
            const response = await api.get(`api/post/public-detail/${postId}/`);
            setPostData(response.data);
            // setPostData(mockPost1);
        } catch (error) {
            toastText("찾으시는 게시물이 존재하지 않습니다!");
            navigate("/");
        }
    }

    return (
        <div className="m-auto w-full md:max-w-xl min-h-screen flex flex-col justify-center py-5 px-0">
            {/* Project Card Details */}
            <div className="px-5 flex justify-between items-center">
                <div className="flex items-center">
                    <div className="w-1 h-8 bg-[#D9D9D9] mr-3"></div>
                    <span className="text-sm">{postData?.project_card?.title ?? ""}</span>
                </div>
                <ProjectInvitedUsers
                    invitedUsers={postData?.project_card?.accepted_users ?? []}
                    projId={postData?.project_card?.id ?? ""}
                />
            </div>
            {/* Post Content & Date */}
            <div className="px-5 py-3">
                <PostContent
                    content={postData?.content ?? ""}
                    taggedUsers={postData?.tagged_users ?? []}
                    projId={postData?.project_card?.id ?? 0}
                />
                <span className="text-xs text-[#595959]">
                    {postData?.created_at ? new Date(postData.created_at).toLocaleDateString("ru-RU") : ""}
                </span>
            </div>
            {/* Post Images */}
            {postData?.images?.length > 0
                && <PostImages images={postData.images}/>}
            {/* Interactions (static) */}
            <div className="flex justify-between">
                <div className="flex gap-2 opacity-75 px-5 py-3">
                    {/* Like Button */}
                    <div className="flex items-center gap-1">
                        <img src={likeIcon} alt="like" className="w-4 h-4"/>
                        <span className="text-sm">{postData?.like_count ?? 0}</span>
                    </div>
                    {/* Comment Button */}
                    <div className="flex items-center gap-1">
                        <img src={commentsIcon} alt="comments" className="w-4 h-4"/>
                        <span className="text-sm">{postData?.comment_count ?? 0}</span>
                    </div>
                </div>
                {/* Options Button */}
                <div
                    className="flex items-center cursor-pointer px-5 py-3 hover:opacity-50"
                    onClick={() => setIsOptionsOpen(!isOptionsOpen)}
                >
                    <img src={threeDotsIcon} alt="options" className="w-5 h-5" style={{transform: "rotate(90deg)"}}/>
                </div>
                <PostOptions
                    postId={postId}
                    isOpen={isOptionsOpen}
                    onClose={() => setIsOptionsOpen(false)}
                />
            </div>
            <PostDetailViewLoginCard/>
        </div>
    )
}

/**
 * Post Options Modal
 * @param props - isOpen, onClose, postId
 * @returns {JSX.Element}
 * @constructor
 */

function PostOptions(props) {
    const {isOpen, onClose, postId} = props;
    const [isReportOpen, setIsReportOpen] = useState(false);

    const handleCopyLink = () => {
        // Copy post link to clipboard
        try {
            navigator.clipboard.writeText(window.location.href);
            toastText("게시글 링크가 복사되었습니다.");
            onClose();
        } catch (error) {
            toastText("게시글 링크 복사에 실패했습니다.");
        }
    }

    const handleOpenReport = () => {
        setIsReportOpen(true);
        onClose();
    }

    return (
        <Fragment>
            <Modal isOpen={isOpen} onClose={onClose}>
                <div className="p-5 pb-0 flex gap-8 items-center justify-center">
                    <div
                        className="flex flex-col items-center gap-2 cursor-pointer hover:opacity-50"
                        onClick={handleCopyLink}
                    >
                        <img src={linkIcon} alt="link" className="w-12 h-12"/>
                        <span className="text-xs">링크 복사</span>
                    </div>
                    <div
                        className="flex flex-col items-center gap-2 cursor-pointer hover:opacity-50"
                        onClick={handleOpenReport}
                    >
                        <img src={reportIcon} alt="report" className="w-12 h-12"/>
                        <span className="text-xs">신고</span>
                    </div>
                </div>
            </Modal>
            <ReportModal
                isOpen={isReportOpen}
                onClose={() => setIsReportOpen(false)}
                postId={postId}
            />
        </Fragment>
    )
}