import React from "react";
import "../styles/ReusableBottomSheet.css";

const ReusableBottomSheet = ({ isOpen, setIsOpen, children }) => {
    const closeSheet = () => setIsOpen(false);

    return (
        <>
            <div
                className={`rb-overlay ${isOpen ? "show" : ""}`}
                onClick={closeSheet}
            />
            <div className={`rb-sheet ${isOpen ? "show" : ""}`}>
                {/* header */}
                <div
                    className={"rb-header"}
                >
                    <div className={"rb-header-handle"} />
                </div>
                {/* content */}
                <div className="rb-content">
                    {children}
                </div>
            </div>
        </>
    );
};

export default ReusableBottomSheet;
