import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";

/**
 * Project invited user avatars
 * @param props {ProjectInvitedUsersProps}
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProjectInvitedUsers(props) {
    const {invitedUsers, projId} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const firstUser = invitedUsers[0];
    const secondUser = invitedUsers[1];
    const remainingCount = invitedUsers.length - 2;

    const handleModalOpen = () => {
        setIsModalOpen(true);
    }

    return (
        <Fragment>
            <div className="flex cursor-pointer hover:opacity-50 transition-all" onClick={handleModalOpen}>
                {firstUser && <img src={firstUser.image} alt="user"
                                   className="w-8 h-8 rounded-full border-2 border-solid border-white"/>}
                {secondUser && <img src={secondUser.image} alt="user"
                                    className="w-8 h-8 rounded-full -ml-2.5 border-2 border-solid border-white"/>}
                {remainingCount > 0 && <div
                    className="w-8 h-8 rounded-full -ml-2.5 border-2 border-solid border-white bg-[#595959] flex items-center justify-center">
                    <span className="text-sm text-white text-center">+{remainingCount}</span>
                </div>}
            </div>
            <InvitedUsersModal
                invitedUsers={invitedUsers}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                projId={projId}
            />
        </Fragment>
    )
}

/**
 * InvitedUsersModal component
 * @param props {InvitedUsersModalProps}
 * @returns {JSX.Element}
 * @constructor
 */
function InvitedUsersModal(props) {
    const {invitedUsers, isOpen, onClose, projId} = props;
    const navigate = useNavigate();

    const goToProfile = (userId) => {
        navigate(`/profiles/${userId}?view=project&projId=${projId}`);
    }

    const noFeedback = (e) => {
        e.stopPropagation();
    }

    return (
        <div
            className={`fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center ${isOpen ? "" : "hidden"}`}
            onClick={onClose}
        >
            <div className="bg-white w-80 rounded-md p-5 flex flex-col gap-3" onClick={noFeedback}>
                <span className="text-lg font-bold text-center">태그된 사용자</span>
                <div className="max-h-64 flex flex-col items-center gap-3">
                    {invitedUsers.map((user, index) => (
                        <div
                            key={index}
                            className="flex items-center gap-2 cursor-pointer hover:opacity-50 transition-all"
                            onClick={() => goToProfile(user.id)}
                        >
                            <img src={user.image} alt="user"
                                 className="w-8 h-8 rounded-full border-2 border-solid border-white"/>
                            <span>{user.user_name}</span>
                        </div>
                    ))}
                </div>
                <button
                    className="bg-[#0923A9] text-white p-2 border-none cursor-pointer hover:opacity-50 transition-all"
                    onClick={onClose}
                >
                    닫기
                </button>
            </div>
        </div>
    )
}

/**
 * @typedef ProjectInvitedUsersProps
 * @property {Array.<InvitedUser>} invitedUsers
 * @property {number} projId
 */

/**
 * @typedef InvitedUsersModalProps
 * @property {Array.<InvitedUser>} invitedUsers
 * @property {number} projId
 * @property {boolean} isOpen
 * @property {function} onClose
 */

/**
 * @typedef InvitedUser
 * @property {number} id
 * @property {string} image
 * @property {string} user_name
 */