import React, {useEffect, useState} from 'react';
import "../../styles/NewInvitation/NewInvitation.css";
import "../../styles/Experience/Experience.css";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {toastText} from "../../components/Toast/Toast.jsx";
import retrieveProjectCardInvitationLinkFromCode
    from "../../libs/apis/ProjectCardInvitationLink/retreiveProjectCardInvitationLinkFromCode.js";
import acceptInvitationLink from "../../libs/apis/ProjectCardInvitationLink/acceptInvitationLink.js";

/**
 *
 * 1. The user must be authenticated.
 * 2. The navigation state must contain the invitation code. (`invitationCode` field)
 *    If not, navigate to the root page.
 * */
const AuthorizedInvitationWelcome = () => {

    const navigate = useNavigate();
    const location = useLocation();

    if ((location.state == null)
        || ((location.state.invitationCode == null))) {
        toastText("잘못된 접근입니다.");
        return <Navigate to="/" />;
    }

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [invitationInfo, setInvitationInfo] = useState(null);
    const [error, setError] = useState(false);
    const [isAcceptLoading, setIsAcceptLoading] = useState(false);

    const logOut = async () => {
        await localStorage.clear();
        navigate("/");
    };

    const fetchInvitationInfo = async () => {
        await setIsDataLoading(true);
        await setError(false);

        try {
            const invitationData =
                await retrieveProjectCardInvitationLinkFromCode(location.state.invitationCode);
            console.log(invitationData);
            await setInvitationInfo(invitationData);
        } catch (e) {
            console.log(e);
            await setError(true);
        } finally {
            await setIsDataLoading(false);
        }
    }

    const acceptInvitation = async () => {
        setIsAcceptLoading(true);
        try {
            const res = await acceptInvitationLink(invitationInfo.inviter.id, invitationInfo.project_card.id);
            if (res?.duplicated && res.duplicated) {
                toastText("이미 함께하는 프로젝트입니다. 팀블 앱에서 확인해주세요!");
                navigate("/");
                return;
            }
            toastText("초대를 수락했습니다. 팀블 앱에서 확인해주세요!");
            navigate("/");
        } catch (error) {
            toastText("초대 수락에 실패했습니다. 다시 시도해주세요.");
            console.error("Error in accepting invitation", error);
        } finally {
            setIsAcceptLoading(false);
        }
    };

    useEffect(() => {
        fetchInvitationInfo();
    }, []);

    if (isDataLoading || (invitationInfo == null)) {
        return (
            <div className="exp-loader-container">
                <div className="exp-loader" />
            </div>
        );
    }

    if (!isDataLoading && error) {
        return (
            <div className="exp-body exp-with-pd-28">
                <div className="exp-error-container">
                    {"초대 정보를 불러오는 데 실패했습니다."}
                </div>
            </div>
        );
    }

    return (
        <div
            className={"newInv-container"}
            style={{
                paddingTop: "20px"
            }}
        >
            {/* logout button */}
            <div
                className={"newInv-top-logout-container"}
            >
                <button
                    className={"newInv-top-logout-button"}
                    onClick={async () => {
                        await logOut();
                    }}
                >
                    {"로그아웃"}
                </button>
            </div>
            {/* invitation message */}
            <div className="exp-inv-title-container">
                <div className="exp-inv-title-row">
                    <span className="exp-inv-title primary-color">
                        {`${invitationInfo["inviter"]["profile"]["user_name"]}`}
                    </span>
                    <span className="exp-inv-title">{`님의`}</span>
                </div>
                <div className="exp-inv-title-row">
                    <span className="exp-inv-title primary-color">
                        {`‘${invitationInfo["project_card"]["title"]}’`}
                    </span>
                    <span className="exp-inv-title">
                        {` 프로젝트 초대를 수락하시겠어요?`}
                    </span>
                </div>
            </div>
            {/* buttons */}
            <div
                className={"newInv-button-container"}
                style={{
                    marginTop: "80px"
                }}
            >
                <button
                    className={`exp-save-button`}
                    onClick={async () => {
                        // accept
                        await acceptInvitation();
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {
                        isAcceptLoading ?
                            <div
                                className={"exp-button-loader"}
                            >
                                {/* intentional empty */}
                            </div>
                            :
                            "수락하기"
                    }
                </button>
                <button
                    className={`exp-secondary-button`}
                    onClick={async () => {
                        // reject
                        toastText("초대를 거절했어요.");
                        navigate("/");
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: '12px'
                    }}
                >
                    {"거절하기"}
                </button>
            </div>
        </div>
    );
};

export default AuthorizedInvitationWelcome;