import {useState} from "react";
import ImagePreview from "./ImagePreview.jsx";

/**
 *
 * @param props {PostImagesProps}
 * @constructor
 */
export default function PostImages(props) {
    const {images} = props;
    const [previewImageUrl, setPreviewImageUrl] = useState(null);

    if (images.length < 1) {
        return null;
    }

    const imagesContent = getImagesContent(images, setPreviewImageUrl);

    return (
        <div>
            {imagesContent}
            <ImagePreview
                isOpen={previewImageUrl !== null}
                close={() => setPreviewImageUrl(null)}
                image={previewImageUrl ?? ""}
            />
        </div>
    )
}

function getImagesContent(images, onPreview) {
    if (images.length === 1) {
        return (
            <img
                src={images[0].image}
                alt="post"
                className="w-full h-48 md:h-64 object-cover rounded-md"
                onClick={() => onPreview(images[0].image)}
            />
        )
    }

    if (images.length === 2) {
        return (
            <div className="grid grid-cols-2 gap-0.5">
                <img
                    src={images[0].image}
                    alt="post"
                    className="w-full h-48 md:h-64 object-cover rounded-l-md"
                    onClick={() => onPreview(images[0].image)}
                />
                <img
                    src={images[1].image}
                    alt="post"
                    className="w-full h-48 md:h-64 object-cover rounded-r-md"
                    onClick={() => onPreview(images[1].image)}
                />
            </div>
        )
    }

    return (
        <div className="grid grid-cols-2 gap-0.5">
            <img
                src={images[0].image}
                alt="post"
                className="w-full h-48 md:h-64 object-cover rounded-l-md"
                onClick={() => onPreview(images[0].image)}
            />
            <div className="flex flex-col gap-0.5">
                <img
                    src={images[1].image}
                    alt="post"
                    className="w-full h-24 md:h-32 object-cover rounded-tr-md"
                    onClick={() => onPreview(images[1].image)}
                />
                <img
                    src={images[2].image}
                    alt="post"
                    className="w-full h-24 md:h-32 object-cover rounded-br-md"
                    onClick={() => onPreview(images[2].image)}
                />
            </div>
        </div>
    )
}

/**
 * @typedef PostImagesProps
 * @property {Array.<PostImage>} images
 *
 * @typedef PostImage
 * @property {number} id
 * @property {string} image
 * @property {string} created_at
 */